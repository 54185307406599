// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-pages-offer-index-tsx": () => import("./../../../src/components/pages/Offer/index.tsx" /* webpackChunkName: "component---src-components-pages-offer-index-tsx" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-career-tsx": () => import("./../../../src/pages/career.tsx" /* webpackChunkName: "component---src-pages-career-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-our-projects-tsx": () => import("./../../../src/pages/ourProjects.tsx" /* webpackChunkName: "component---src-pages-our-projects-tsx" */),
  "component---src-pages-rnd-tsx": () => import("./../../../src/pages/rnd.tsx" /* webpackChunkName: "component---src-pages-rnd-tsx" */),
  "component---src-pages-rodo-tsx": () => import("./../../../src/pages/rodo.tsx" /* webpackChunkName: "component---src-pages-rodo-tsx" */)
}

